<template>
        <div :class="`content `">

            <div class="container-fluid">
                <div class="social-dash-wrap">
                    <!-- <div class="row">
                        <div class="col-lg-12">

                            <div class="breadcrumb-main">
                                <h4 class="text-capitalize breadcrumb-title">{{ t('welcome',{},{locale:getLocal}) }}</h4>
           
                            </div>

                        </div>
                    </div> -->
                    <div class="row margin-for-logo-center">
                        <div class="col-md-12 p-0">
                            <div class="card bg-gradient-red-orange">
                                <img class="logo-head-center" src="/images/numchok.png" alt="">
                                <div class="card-body">
                                    <div class="row mt-5">
                                        <div class="col-12">
                                            <p class="text-center text-white" >กรุณาเลือกประเภท</p>
                                        </div>
                                        <div class="col-12 p-0">
                                            <div class="d-flex justify-content-center">
                                                <SearchLotto ref="SearchLottoRef" @SearchLottoReturnData="SearchLottoReturnData"  />
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <!-- <div class="row mb-3">
                                        <div class="col-12">
                                            <h4>เลขเด็ด</h4>
                                        </div>
                                        <div class="col-12 border-box-yellow">
                                            
                                            <div class="d-flex justify-content-center">
                                                <ListLotto ref="ListLottoPopularRef" :start="0" :length="4" searchNumber=""  lottoType="popular" />
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="row mb-3">
                                        <div class="col-12 ">
                                            <div class="d-flex justify-content-center">
                                                <ListLotto v-if="searchNumberData" ref="ListLottoRef" :start="0" :length="12" :searchNumber="searchNumberData" :key="searchNumberData" lottoType="" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row mb-3">
                                        <div class="col-12">
                                            <h4>เลขใกล้เคียง</h4>
                                        </div>
                                        <div class="col-12 box-thin">
                                            
                                            <div class="d-flex justify-content-center">
                                                <ListLotto ref="ListLottoSimilarRef" :start="0" :length="4" :searchNumber="searchNumberData" :key="searchNumberData" lottoType="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-12">
                                            <h4>เลขเบิ้ล</h4>
                                        </div>
                                        <div class="col-12 box-thin">
                                            
                                            <div class="d-flex justify-content-center"> 
                                                <ListLotto ref="ListLottoDoubleRef" :start="0" :length="4" :searchNumber="searchNumberData" :key="searchNumberData" lottoType="" />
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</template>

<script>


import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import SearchLotto from '@/components/SearchLotto.vue';
import ListLotto from '@/components/ListLotto.vue';
import { useI18n } from 'vue-i18n'
export default {
  name: 'Lottery',
  components: {
    SearchLotto,
    ListLotto
  },
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  data: function () {
    return {
        searchNumberData:''
      }
    },
  mounted() {
     document.title = 'Lottory'
     this.$store.dispatch('display/setNavName','Lottory')
  },
  methods: {
    async SearchLottoReturnData(val){
        this.searchNumberData=val
    }
  },
  computed:{
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    }
  }

}

</script>
<style scoped>
.logo-head-center{
    width: 200px;
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translate(-50%,-50%)
}
</style>